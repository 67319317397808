.wrapper {
  margin: 10px 0;

  &:last-of-type,
  > div:first-child {
    margin-bottom: 0;
  }

  > * + div {
    margin-top: 5px;
  }
}

.edenredButtonContent {
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  align-items: center;
}

.edenredIcon {
  position: absolute;
  left: 0;
}

.edenredButtonText {
  font-size: 17px;
  color: #ffffff;
  font-weight: 600;
}
