.page {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
}

.submenu {
  width: 25vw;
  max-width: 350px;
  padding: 5px;
  position: relative;
}

.submenuButton {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  margin-bottom: 5px;
  min-width: 175px;
  width: 100%;

  a {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 10px;
  }

  h3 {
    display: inline-block;
    margin: 0;
    color: black;
    user-select: none;
  }

  svg {
    display: inline-block;
    align-self: center;
  }
}

.contentPane {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  max-width: 445px;
  width: 45vw;
  min-width: 355px;
}

@media screen and (max-width: 772px) {
  .wrapper {
    width: 100%;
  }

  .submenuMobile {
    width: 100%;
    padding: 0 3vw;
  }

  .submenu {
    width: 100%;
    max-width: unset;
    position: relative;
  }

  .contentPane {
    width: 100%;
  }
}
