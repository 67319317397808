.popupTitle {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}

.hr {
  margin: 0px -8px 0 -8px;
  border-top: 0.5px solid rgba(200, 199, 204, 0.5);
}

.sectionTitle {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: left;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 4px solid rgba(200, 199, 204, 0.5);
  margin: 0 -8px;
  padding: 0 0.5rem;
  font-size: 0.9rem;

  > h2 {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0px;
  }

  &:last-child {
    border-bottom: none;
  }

  > hr:last-child {
    display: none;
  }

  > button {
    height: 44px;
  }
}

.orderDetailsPill {
  padding-bottom: 0.5rem;
}

.roundUpForCharity {
  padding-bottom: 0.5rem !important;
}

.inlineOfferItem {
  margin-top: -0.5rem;
}

.deliveryInstructions {
  padding-bottom: 0.5rem !important;
  > div {
    margin-top: 0 !important;
    > section {
      border-radius: 0 !important;
      box-shadow: none !important;
      > header {
        padding: 0 !important;
        > h3 {
          font-size: 0.9rem;
          font-weight: 600;
        }
      }
      > div {
        padding: 0 !important;
        margin-top: 0.5rem !important;
        > div {
          padding: 0 !important;
        }
      }
    }
  }
}

.link {
  font-size: 11px;
  margin-top: -0.5rem;
  > div {
    > p {
      font-weight: 600 !important;
    }
  }
}
