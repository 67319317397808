.card {
  box-shadow: 0;
  border: solid 0.5px;
}
.loyaltyEnroll > div:not(:last-child) {
  padding-bottom: 10px;
}
.loyaltyEnroll > div:not(:first-child) {
  padding-top: 10px;
  border-top: solid 0.5px rgba(200, 199, 204, 0.5);
}
.loyaltySlice {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  padding: 0px 8px;
}
.loyaltySliceText {
  width: 100%;
  padding-left: 10px;
}
.loyaltySliceText > *:not(:first-child) {
  padding-top: 10px;
}
.title {
  font-weight: bold;
  font-size: 13px;
}
.joinSwitch {
  display: flex;
  justify-content: flex-end;
}
.joinSwitch.switchWrapper {
  padding-left: 40px;
}
.switchLabel {
  font-size: 12px;
  font-weight: 700;
  padding-right: 8px;
  text-transform: uppercase;
}
