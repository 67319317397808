.wrapper {
  background-color: #e8eaed;
  border-radius: 8px;
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
}
.wrapper:last-of-type {
  margin-bottom: 0;
}
.cardType {
  background-color: #929292;
  border-radius: 4px;
  color: #e8eaed;
  font-weight: 600;
  height: 26px;
  margin-right: 10px;
  padding: 3px 0;
  position: relative;
  text-align: center;
  width: 36px;
}
.cardType::before {
  content: '';
  border-radius: 1px;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 5px;
  background-color: #ededed;
}
.cardType::after {
  content: '';
  position: absolute;
  bottom: 6px;
  left: 5px;
  right: 5px;
  height: 3px;
  background-color: #5c5c5c;
}
.cardText {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.creditCardText {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 2px;
  text-transform: uppercase;
}
.cardNumber {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}
.remove {
  background-color: transparent;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  max-height: 36px;
  padding: 4px 10px;
  text-transform: uppercase;
}
.remove:disabled {
  cursor: not-allowed;
}
