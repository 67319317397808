.wrapper {
  background-color: white;
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  top: 14vh;
  left: 50%;
  transform: translateX(-50%);
  max-width: 360px;
  min-width: 260px;
  border-radius: 15px;
  box-shadow: 0 1px 5px 0 rgba(137, 137, 137, 0.26);
  padding: 0px 15px 0px 10px;
}
@media screen and (max-width: 450px) {
  .wrapper {
    width: 100%;
  }
}
.iconWrapper {
  display: flex;
  border-right: solid 0.5px rgba(151, 151, 151, 0.2);
  padding: 15px 15px 15px 5px;
  margin: auto 0;
}
.message {
  width: 100%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
}
.messageHeading {
  margin: 5px 0 5px 0;
  font-size: 1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.12px;
  color: #000000;
  flex-grow: 1;
  text-align: left;
}
.messageText {
  padding: 8px 0 8px 0;
  font-size: 0.8rem;
}
.closeButton {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  top: 7px;
  right: 6px;
  position: absolute;
  cursor: pointer;
  background-color: #4a4a4a;
}
