.wrapperInner {
  padding: 8px 8px 8px 12px;
  width: 100%;
}

.subheading {
  position: relative;
  background-color: #e8eaed;
  color: #6d6d72;
  font-size: 12px;
  margin: 0;
  padding: 4px 12px;
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.5px;
    margin: 15px 0 0;
    background-color: #979797;
  }
}

.uneditable {
  font-size: 11px;
  padding: 0 12px;
}

.recaptchaPolicy {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  color: #4a4a4a;
}
