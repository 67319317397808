.upsellContainer {
  display: flex;
  flex-direction: column;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #eff9f1;
  margin-bottom: -8px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.5px 10px 0px;
}
.imgColumn {
  flex: 1;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  position: relative;
}
.image {
  width: 100%;
}
.iconButtonContainerStyle {
  position: absolute;
  right: 5px;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  padding-top: 4px;
  padding-bottom: 18px;
  flex-direction: row;
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: #234d00;
  flex: 3;
}
.upgradeButton {
  padding: 8px 13px;
  border-radius: 8px;
  background-color: #234d00 !important;
  flex: 1;
  min-height: auto;
  line-height: normal;
}
