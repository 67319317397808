.storeTradeDetailsContainer {
  display: grid;
  grid-template-columns: 26px 160px auto;
  row-gap: 10px;
  font-size: 15px;
  margin-bottom: 10px;
}
.storeTradeDetailsContainer .storeTradeDetailsName {
  font-weight: bold;
}
.storeTradeDetailsContainer .storeTradeDetailsValue {
  color: #4a4a4a;
  font-size: 14px;
}
