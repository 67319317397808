.viewAllButton {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0;
  color: #007aff;
  cursor: pointer;
  padding-block: 0.625rem;
}
.ingredientContainer {
  margin-inline: 0.5rem;
}
