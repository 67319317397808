.wrapper {
  border-radius: 15px;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
}

.content {
  padding: 10px 10px;

  h2 {
    margin-top: 5px;
  }
}

.noMethods {
  text-align: center;
  color: rgb(137, 137, 137);
}
