.wrapperInner {
  padding: 10px 10px 8px 10px;
}
.wrapper {
  border-radius: 15px;
  margin-top: 20px;
  padding: 0px 0px 10px 0;
  background-color: white;
  box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.15);
}
.wrapper h2 {
  margin-top: 5px;
}
.wrapper .paragraph {
  margin: 0;
}
.wrapper .centerButton {
  text-align: center;
}
.wrapper button {
  color: white;
  width: 100%;
  height: 50px;
  max-width: 350px;
  border-radius: 8px;
  font-size: large;
  font-weight: 600;
  border: none;
}
@media screen and (max-width: 772px) {
  .wrapper button {
    max-width: unset;
    width: 70%;
  }
}
@media screen and (max-width: 480px) {
  .wrapper button {
    max-width: unset;
    width: 100%;
  }
}
