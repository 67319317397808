.container {
  background-color: #fff;
  padding: 10px;
  border-radius: 19px 19px 0px 0px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.203371);
  display: flex;
}
.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 17px;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  font-weight: 600;
  color: #ffffff;
}
:global(.kiosk-web) .button > div {
  font-size: 30px;
}
:global(.kiosk-web) .button svg {
  width: 35px;
  height: 28px;
}
.countBadge {
  background-color: #cf343d;
  padding: 10px 6px;
  border-radius: 15px;
  margin: 0px 9px 0px 2.5px;
  font-size: 15px;
}
.cartButton {
  height: 50px;
  margin-right: 10px;
  background-color: #3478f6 !important;
  flex: 1;
}
:global(.kiosk-web) .cartButton {
  height: 100px;
}
.placeOrderButton {
  height: 50px;
  background-color: #1fc36a;
  flex: 1;
}
:global(.kiosk-web) .placeOrderButton {
  height: 100px;
}
