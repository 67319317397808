@import '../../../hooks-and-hocs/theme/theme-data/standard-theme.less';

.container {
  min-width: 20rem;
  height: 4.5rem;
  border-radius: 0.5rem !important;
  padding: 1rem;

  & + & {
    margin-top: 1rem;
  }

  &.takeAwayContainer {
    background-color: @standardColors[button-background-blue-alt] !important;
  }
  &.dineInContainer {
    background-color: @standardColors[button-background-red] !important;
  }
}

.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  height: 100%;
  align-items: center;
}

.buttonIcon {
  width: 2rem;
  height: 2rem;
}
.buttonIconSmall {
  width: 1rem;
  height: 1rem;
}

.buttonLabel {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  margin: 0 1rem;

  .buttonIcon + & {
    text-align: left;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100%;
}
